<template>
  <div>
    <FilterOverview title="Übersicht" :isBusy="isBusy" @reload="onReload">
      <template #filter> <AgenturMasterFilter /></template>
      <template #table="{ tableHeight, heightComponentInlineStyle }">
        <AgenturMasterOverviewTable
          :tableHeight="tableHeight"
          :heightComponentInlineStyle="heightComponentInlineStyle"
        />
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import { GET_AGENTUR_MASTER } from '@/core/agenturen/stores/agenturMaster.module';
import { mapState } from 'vuex';

import AgenturMasterOverviewTable from '@/components/agenturen/agentur-master-overview-table.vue';
import AgenturMasterFilter from '@/components/agenturen/agentur-master-filter.vue';
import FilterOverview from '@/components/common/filter-overview.vue';

export default {
  name: 'KundeOverviewComponent',
  components: {
    AgenturMasterOverviewTable,
    AgenturMasterFilter,
    FilterOverview,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isBusy: state => state.agenturMaster.isBusy,
    }),
  },
  methods: {
    onReload() {
      this.$store.dispatch(GET_AGENTUR_MASTER);
    },
  },
};
</script>
