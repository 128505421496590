<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterId
      class="mt-4"
      @input="setAgenturMasterIdFilter"
      :optionsArray="agenturMasterIdFilterOptions"
      :value="agenturMasterIdFilter"
      :storeFunction="getAgenturFilterFunction"
      :placeholder="$t('AGENTUREN.AGENTURNUMMER')"
      objectName="Agentur"
    ></FilterId>
    <b-form-checkbox
      class="mt-4"
      @input="setAtleastOneAgenturFilter"
      :checked="atleastOneAgenturFilter"
      switch
      size="md"
    >
      Mindestens eine Dublette
    </b-form-checkbox>
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button :disabled="isCurrentFilterApplied" lg block @click="onClickSaveFilter()" variant="primary">
        Filter anwenden
      </b-button>
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter()"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      ></b-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import {
  GET_AGENTUR_MASTER,
  RESET_FILTER,
  SET_AGENTUR_MASTER_ID_FILTER,
  GET_AGENTUR_MASTER_ID_FILTER_OPTIONS,
  SET_AGENTUR_MASTER_ALEAST_ONE_AGENTUR_FILTER,
  SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED,
} from '@/core/agenturen/stores/agenturMaster.module';
import FilterId from '@/components/common/filter-id.vue';

export default {
  components: {
    FilterId,
  },
  computed: {
    ...mapState({
      isAnyFilterApplied: state => state.agenturMaster.isAnyFilterApplied,
      isCurrentFilterApplied: state => state.agenturMaster.isCurrentFilterApplied,
      appliedAgenturMaster: state => state.agenturMaster.appliedAgenturMasterFilter,
      agenturMasterIdFilterOptions: state => state.agenturMaster.agenturMasterIdFilterOptions,
      agenturMasterIdFilter: state => state.agenturMaster.filter.agenturMasterIdFilter,
      atleastOneAgenturFilter: state => state.agenturMaster.filter.atleastOneAgenturFilter,
    }),
  },
  methods: {
    getAgenturFilterFunction(id) {
      return this.$store.dispatch(GET_AGENTUR_MASTER_ID_FILTER_OPTIONS, { idFilter: id });
    },
    onClickSaveFilter() {
      this.$store.dispatch(GET_AGENTUR_MASTER);
    },
    onClickResetFilter() {
      this.$store.dispatch(RESET_FILTER);
    },
    setAgenturMasterIdFilter(id) {
      this.$store.commit(SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_AGENTUR_MASTER_ID_FILTER, id);
    },
    setAtleastOneAgenturFilter(isAtleastOneAgentur) {
      this.$store.commit(SET_AGENTUR_MASTER_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_AGENTUR_MASTER_ALEAST_ONE_AGENTUR_FILTER, isAtleastOneAgentur);
    },
  },
};
</script>
