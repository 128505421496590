<template>
  <div class="card card-custom" :style="heightComponentInlineStyle">
    <div class="card-body d-flex flex-column justify-content-between">
      <b-table
        table-class="agenturMaster-table"
        :key="'b-table-agenturMaster-table'"
        :id="'b-table-agenturMaster-table'"
        :ref="'b-table-agenturMaster-table'"
        :empty-filtered-text="$t('AGENTUREN.NODATA')"
        :empty-text="$t('AGENTUREN.NODATA')"
        show-empty
        responsive
        primary-key="id"
        :items="agenturMaster"
        :fields="fields"
        :sort-by="orderBy[0].split(' ')[0]"
        :sort-desc="orderBy[0].split(' ')[1] === 'desc'"
        :sort-direction="orderBy[0].split(' ')[1]"
        :sticky-header="tableHeight + 'px'"
        :busy="isBusy"
        no-local-sorting
        no-sort-reset
        @sort-changed="onSortChanged"
      >
        <template #cell(name)="row">
          {{ row.item.name || '-' }}
        </template>
        <template #cell(adresse)="row">
          <template v-if="row.item.strasse && row.item.plz && row.item.ort && row.item.land">
            {{ row.item.strasse }}, {{ row.item.plz }} {{ row.item.ort }} {{ row.item.land }}
          </template>
          <template v-else>-</template>
        </template>

        <template #cell(agenturen)="row">
          {{ getAgenturMasterIdArray(row.item, true).join(', ') || '-' }}
        </template>

        <template #cell(actions)="row">
          <ActionButton
            :link="
              'https://crmplus.zoho.eu/skrreisengmbh/index.do/cxapp/crm/org20070234763/search?sModules=Accounts&searchword=' +
              row.item.id
            "
            :title="$t('COMMON.ZOHO')"
            iconClass="fas fa-users"
          />
        </template>
      </b-table>
      <SKRPagination
        v-model="currentPage"
        :isLoading="isBusy"
        :totalRows="count"
        :currentRows="agenturMaster.length || 0"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.b-table-sticky-header > .table.b-table > thead > tr > th) {
  position: sticky !important;
}
.table-dropdown > .btn {
  padding: 0;
}
</style>

<script>
import {
  GET_AGENTUR_MASTER,
  AGENTUR_MASTER_SORT_CHANGED,
  AGENTUR_MASTER_PAGE_CHANGED,
  GET_AGENTUR_MASTER_VORGAENGE,
} from '@/core/agenturen/stores/agenturMaster.module';

import { mapState } from 'vuex';
import ActionButton from '@/components/common/action-button.vue';

import SKRPagination from '@/components/common/skr-pagination.vue';

export default {
  components: {
    SKRPagination,
    ActionButton,
  },
  props: {
    tableHeight: {
      type: Number,
      required: false,
    },
    heightComponentInlineStyle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'id',
          label: this.$t('AGENTUREN.AGENTURNUMMER'),
          sortable: true,
          class: 'position-sticky b-table-sort-icon-left',
        },
        {
          key: 'updatedDateUtc',
          label: this.$t('COMMON.GEANDERT'),
          formatter: value => {
            return this.$options.filters.dateTime(value);
          },
          sortable: true,
          class: 'position-sticky b-table-sort-icon-left',
        },
        {
          key: 'name',
          label: this.$t('REISEN.COMMON.NAME'),
        },
        {
          key: 'email',
          label: this.$t('AUTH.INPUT.EMAIL'),
        },
        {
          key: 'adresse',
          label: this.$t('COMMON.ADRESSE'),
        },
        {
          key: 'telefon',
          label: this.$t('COMMON.TELEFONPRIVAT'),
        },
        {
          key: 'fax',
          label: this.$t('COMMON.FAX'),
        },
        {
          key: 'agenturen',
          label: this.$t('DUBLETTEN.DUBLETTEN'),
          sortable: true,
          class: 'position-sticky b-table-sort-icon-left',
        },
        {
          key: 'actions',
          label: this.$t('MENU.ACTIONS'),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isBusy: state => state.agenturMaster.isBusy,
      agenturMaster: state => state.agenturMaster.agenturMaster,
      orderBy: state => state.agenturMaster.orderBy,
      count: state => state.agenturMaster.count,
    }),
  },
  mounted() {
    this.$store.dispatch(GET_AGENTUR_MASTER);
  },
  methods: {
    getAgenturMasterIdArray(agenturMaster, removeOwnId = false) {
      // Remove own id from agenturMaster array and then join all ids to one array
      return agenturMaster.agenturen
        .filter(agentur => !removeOwnId || agentur.id !== agenturMaster.id)
        .map(agentur => agentur.id);
    },
    onToggleDetailsClick($event, row) {
      if (!row.detailsShowing) {
        this.$store.dispatch(GET_AGENTUR_MASTER_VORGAENGE, { agenturMasterId: row.item.id });
      }
      row.toggleDetails();
    },
    onPageChange(ctx) {
      this.$store.dispatch(AGENTUR_MASTER_PAGE_CHANGED, { pageContext: ctx });
      window.scrollTo(0, 0);
    },
    onSortChanged(ctx) {
      this.$store.dispatch(AGENTUR_MASTER_SORT_CHANGED, { sortContext: ctx });
      window.scrollTo(0, 0);
    },
  },
};
</script>
